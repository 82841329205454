<template>
  <div id="appartmentSection">
    <v-container>
      <v-row>
        <v-col>
          <p class="headline">{{ $t("APPARTMENT") }}</p>
        </v-col>
      </v-row>

      <div style="border: none">
        <v-tabs color="accent-4" right>
          <v-tab v-for="card in cards" :key="card.title">{{
          $t(card.title)
          }}</v-tab>
          <v-spacer></v-spacer>
          <v-tab-item v-for="card in cards" :key="card.title">
            <v-container fluid>
              <v-row>
                <v-carousel cycle hide-delimiter-background show-arrows-on-hover>
                  <v-carousel-item v-for="(img, i) in card.images" :key="i" :src="require('@/assets/' + img.src)">
                  </v-carousel-item>
                </v-carousel>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h2 class="text-uppercase">{{ $t(card.title) }}</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="12">

                  <div v-html="$t( card.description)" class="text-left"></div>
                </v-col>

                <v-col cols="12" sm="12" md="6" lg="6" xl="12">
                  <v-list-item v-for="e in card.appartmentEquipment" :key="e.name" class="text-left">
                    <v-list-item-icon>
                      <v-icon>mdi-checkbox-blank-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t(e.name) }}<br />

                        {{ $t(e.description) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-container>
  </div>
</template>

<script>
//import i18n from "vue-i18n";
export default {
  name: "AppartmentSection",

  data() {
    return {
      cards: [
        {
          title: "ROOM",
          description: "ROOM_DESCRIPTION",
          images: [
            {
              src: "images/appartment/room/1.jpg",
            },
            {
              src: "images/appartment/room/2.jpg",
            },
            {
              src: "images/appartment/room/3.jpg",
            },
            {
              src: "images/appartment/room/4.jpg",
            },
            {
              src: "images/appartment/room/5.jpg",
            },
            {
              src: "images/appartment/room/6.jpg",
            },
            {
              src: "images/appartment/room/7.jpg",
            },
            {
              src: "images/appartment/room/8.jpg",
            },
            {
              src: "images/appartment/room/9.jpg",
            },
          ],
          flex: 4,
          appartmentEquipment: [
            {
              name: "BED_SHORT",
              description: "BED_DESCRIPTION",
            },
            {
              name: "BED_2_SHORT",
              description: "BED_2_DESCRIPTION",
            },
            {
              name: "TV_SHORT",
              description: "TV_DESCRIPTION",
            },
            {
              name: "TABLE_WORK_SHORT",
              description: "TABLE_WORK_DESCRIPTION",
            },
            {
              name: "COFE_MASHINE",
              description: "COFE_MASHINE_DESCRIPTION",
            },
            {
              name: "FRIDGE",
              description: "FRIDGE_DESCRIPTION",
            },
          ],
        },
        {
          title: "KITCHEN",
          description: "KITCHEN_DESCRIPTION",
          flex: 4,
          images: [
            {
              src: "images/appartment/kitchen/1.jpg",
            },
            {
              src: "images/appartment/kitchen/2.jpg",
            },
            {
              src: "images/appartment/kitchen/3.jpg",
            },
            {
              src: "images/appartment/kitchen/4.jpg",
            }, {
              src: "images/appartment/kitchen/5.jpg",
            }, {
              src: "images/appartment/kitchen/6.jpg",
            },
          ],
          appartmentEquipment: [
            {
              name: "OVEN",
              description: "OVEN_DESCRIPTION",
            },
            {
              name: "DISH_WASHER",
              description: "DISH_WASHER_DESRIPTION",
            },
            {
              name: "COOKER",
              description: "COOKER_DESCRIPTION",
            },
            {
              name: "WASHING_MACHINE",
              description: "WASHING_MASHINE_DESCRIPTION",
            },
            {
              name: "KETTLE",
              description: "KETTLE_DESCRIPTION",
            }
          ],
        },
        {
          title: "BATH",
          description: "BATH_DESCRIPTION",
          flex: 4,
          images: [
            {
              src: "images/appartment/bathroom/1.jpg",
            },
            {
              src: "images/appartment/bathroom/2.jpg",
            },
            {
              src: "images/appartment/bathroom/3.jpg",
            },
            {
              src: "images/appartment/bathroom/4.jpg",
            },
          ],
          appartmentEquipment: [
            {
              name: "SHOWER",
              description: "SHOWER_DESCRIPTION",
            },
            {
              name: "HAIR_DRYER",
              description: "HAIR_DRYER_DESCRIPTION",
            },
            {
              name: "SHAMPO",
              description: "SHAMPO_DESCRIPTION",
            },
            {
              name: "SHOWER_GEL",
              description: "SHOWER_GEL_DESCRIPTION",
            },
            {
              name: "CONDITIONER",
              description: "CONDITIONER_DESCRIPTION",
            },

          ],
        },
      ],
    };
  },
};
</script>
<style>
div.v-tab.v-tab--active {
  background-color: black;
  color: white;
}
</style>