
<template>
    <v-app id="inspire" class="application">
        <navigation></navigation>
        <switch-language-section></switch-language-section>
        <v-main class="mt-0 pt-0">
            <check-in-section></check-in-section>
            <footer-section></footer-section>
        </v-main>
    </v-app>
</template>
<script>
import Navigation from "../components/Navigation.vue";
import CheckInSection from "../components/CheckInSection.vue";
import SwitchLanguageSection from "../components/SwitchLanguageSection.vue";
import FooterSection from '../components/FooterSection.vue';

export default {
    name: "CheckInView",
    components: {
        Navigation,
        CheckInSection,
        SwitchLanguageSection,
        FooterSection
    },
    data() {
        return {}
    }
}
</script>