<template>
  <div class="mb-2">
    <v-app-bar app color="white" flat>
      <v-app-bar-nav-icon class="float-left" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <div style="width: 120px; border-style: solid; cursor: pointer;" @click="scrollTo(homeLink)">
        <v-avatar :tile="true" width="30" height="30">
          <img :src="require('@/assets/logo.svg')" width="30" alt="logo" />
        </v-avatar>
        <span>coSI</span>
      </div>
      <v-tabs v-model="active_tab" class="ml-n9" color="grey darken-1"
        v-show="$vuetify.breakpoint.md || $vuetify.breakpoint.lg">
        <v-spacer></v-spacer>
        <v-tab v-for="link in links" :key="link.id" @click="scrollTo(link)">
          {{ $t(link.name) }}
        </v-tab>
      </v-tabs>
      <v-spacer v-show="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"></v-spacer>
      <v-btn class="d-flex white--text rounded-0 mx-1" color="red lighten-1" @click="scrollTo(bookNowLink)" depressed>
        {{ $t("BOOK_NOW") }}
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <!--  -->
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class=" text--accent-4">
          <v-list-item v-for="item of links" :key="item.name" @click="scrollTo(item)">
            <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Navigation",
  data: () => ({
    active_tab: 0,
    bookNowLink: {
      route: "home",
      scrollId: 'bookNowSection'
    },
    homeLink: null,
    links: [
      { id: 1, name: "APPARTMENT", route: "home" },
      { id: 2, name: "TRANSFER", route: "transfer" },
      { id: 3, name: "CHECKIN", route: "checkIn" },
    ],
    group: "",
    drawer: null,
  }),
  created() {
    this.homeLink = this.links[0];
  },
  mounted() {
    const queryParam = this.$route ? this.$route.query : null;
    if (queryParam?.scrollId) {
      this.scrollToHash(queryParam.scrollId);
    }
  },
  watch: {
    $route(to) {
      if (to?.query?.scrollId) {
        this.scrollToHash(to.query.scrollId);
      }
    },
  },
  methods: {
    scrollToHash(scrollId) {
      if (scrollId) {
        $("html, body").animate({
          scrollTop: $('#' + scrollId).offset().top,
        },
          1000
        );
      }
    },
    scrollTo(link) {

      if (this.$route?.path === "/" + link.route) {
        //user is on the right path
        if (link.scrollId) {
          this.scrollToHash(link.scrollId);
        }
      } else if (link.route && link.scrollId) {
        this.$router.push({ path: link.route, query: { scrollId: link.scrollId ? link.scrollId : null } });
      } else if (link.route) {
        this.$router.push({
          path: link.route
        });

      }
    },
  },
};
</script>