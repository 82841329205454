<template>
  <div id="welchomeSection">
    <v-container fluid>
      <v-row>
        <v-col>
          <p class="headline">{{ $t('WELCOME_TO_SI') }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-carousel cycle hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(slide, i) in slides" :key="i" :src="require('@/assets/' + slide.src)">
            <v-container fill-height>
              <v-layout align-center>
                <v-flex>
                  <h3 class="display-3">{{ $t(slide.title) }}</h3>
                  <span class="subheading">{{ $t(slide.text) }}</span>
                </v-flex>
              </v-layout>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slides: [
        {
          title: "coSI-Appartment",
          text: "IN_12_FLOOR",
          src: "images/welcome/1.jpg",
        },

        {
          title: "12_FLOOR",
          text: "ENJOY_THE_VIEW",
          src: "images/welcome/3.jpg",
        },
        {
          title: "3_BED",
          text: "3_BED_DESCRIPTION",
          src: "images/welcome/4.jpg",
        },
      ],
    };
  },
};
</script>