<template>
    <div>
        <v-container id="routeContactSection">


            <v-row>
                <v-col>
                    <p class="headline">{{ $t("CHECK_IN") }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p>{{ $t("DOOR_LOCK_FUNCTION_1") }}</p>
                    <p class="font-weight-bold">{{ $t("DOOR_LOCK_FUNCTION_2") }}</p>
                </v-col>
            </v-row>

            <v-tabs color="accent-4" right>
                <v-tab v-for="card in cards" :key="card.title">
                    {{
                        $t(card.title)
                    }}
                </v-tab>

                <v-tab-item>
                    <v-row>
                        <v-col>
                            <p class="font-weight-bold red">{{ $t("DOOR_LOCK_FUNCTION_3") }}</p>
                            <p class="font-weight-bold">{{ $t("DOOR_LOCK_FUNCTION_4") }}</p>
                            <p>{{ $t("DOOR_LOCK_FUNCTION_5") }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-img max-height="300" max-width="450"
                                :src="require('@/assets/images/checkIn/TiefgarageAusgang.jpg')">
                            </v-img>

                        </v-col>
                        <v-col cols="6">
                            <v-img max-height="300" max-width="450"
                                :src="require('@/assets/images/checkIn/AufzugTiefgarage.jpg')">
                            </v-img>

                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>

                            <p class="font-weight-bold">{{ $t("DOOR_LOCK_FUNCTION_6") }}</p>
                            <p>{{ $t("DOOR_LOCK_FUNCTION_7") }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-img max-height="300" max-width="450"
                                :src="require('@/assets/images/checkIn/EntryKantoEast_B_17.jpeg')"></v-img>
                        </v-col>
                    </v-row>
                </v-tab-item>
                <v-tab-item>
                    <v-row>
                        <v-col>
                            <p class="font-weight-bold red">{{ $t("DOOR_LOCK_FUNCTION_8") }}</p>
                            <p class="font-weight-bold">{{ $t("DOOR_LOCK_FUNCTION_9") }}</p>
                            <p>{{ $t("DOOR_LOCK_FUNCTION_10") }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-img max-height="300" max-width="450"
                                :src="require('@/assets/images/checkIn/TiefgarageAusgang.jpg')">
                            </v-img>

                        </v-col>
                        <v-col cols="6">
                            <v-img max-height="300" max-width="450"
                                :src="require('@/assets/images/checkIn/AusgangKantoSports.jpg')">
                            </v-img>

                        </v-col>
                        <v-col cols="6">
                            <v-img max-height="300" max-width="450"
                                :src="require('@/assets/images/checkIn/ZugangKantoStock3.jpg')">
                            </v-img>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>

                            <p class="font-weight-bold">{{ $t("DOOR_LOCK_FUNCTION_11") }}</p>
                            <p>{{ $t("DOOR_LOCK_FUNCTION_12") }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-img max-height="300" max-width="450"
                                :src="require('@/assets/images/checkIn/EntryKantoEast_N_17.jpeg')">
                            </v-img>

                        </v-col>
                        <v-col cols="6">
                            <v-img max-height="300" max-width="450"
                                :src="require('@/assets/images/checkIn/ZugangKantoStock3.jpg')">
                            </v-img>
                        </v-col>
                    </v-row>


                </v-tab-item>

            </v-tabs>

            <v-row>
                <v-col>
                    <p class="headline">{{ $t("DOOR_LOCK_FUNCTION_13") }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p>{{ $t("DOOR_LOCK_FUNCTION_14") }}</p>
                    <p class="font-weight-bold">{{ $t("DOOR_LOCK_FUNCTION_15") }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div v-html="$t('DOOR_LOCK_FUNCTION_16')"></div>
                </v-col>
            </v-row>


            <v-row>
                <v-col cols="6">
                    <v-img :src="require('@/assets/images/checkIn/nuki_1.jpeg')">
                    </v-img>
                </v-col>
                <v-col cols="6">
                    <v-img :src="require('@/assets/images/checkIn/nuki_2.jpeg')">
                    </v-img>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script>
//import i18n from "vue-i18n";
export default {
    name: "CheckInSection",
    data() {
        return {
            cards: [
                {
                    title: "BEFORE_5_PM",
                },
                {
                    title: "AFTER_5_PM",
                }]

        }
    }
}
</script>