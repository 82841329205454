import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CheckInView from '../views/CheckInView.vue'
import TransferView from '../views/TransferView.vue'
import DisclaimerPrivacyView from '../views/DisclaimerPrivacyView.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
   {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/home/:scrollId',
    name: 'HomeWithParams',
    component: Home
  }, {
    path: '/checkIn',
    name: 'CheckInView',
    component: CheckInView
  }, {
    path: '/transfer',
    name: 'TransferView',
    component: TransferView
  }, {
    path: '/disclaimerPrivaccy',
    name: 'DisclaimerPrivacyView',
    component: DisclaimerPrivacyView
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
