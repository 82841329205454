<template>
    <v-footer padless>
        <v-col>
            Copyright &copy; {{ new Date().getFullYear() }} cosi-stuttgart.de
        </v-col>
        <v-col>
            <v-btn text :to="'disclaimerPrivaccy'" class="float-right">{{ $t('DISCLAIMER_PRIVACY') }}</v-btn>
        </v-col>
    </v-footer>
</template>

<script>
export default {
    name: "FooterSection",
    methods: {


    },
}
</script>