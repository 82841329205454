<template>
  <div>
    <v-container id="routeContactSection">
      <v-row>
        <v-col>
          <p class="headline">{{ $t("ROUTE_AND_CONTACT") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col>
                <v-btn class="white--text rounded-0" color="black lighten-1" depressed to="/checkIn">{{
                    $t("ROUTE_AND_CONTACT")
                }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">

          <v-container fill-height fluid>
            <v-row>
              <iframe width="600px" height="300px"
                src="https://www.google.com/maps?width=600&amp;height=400&amp;hl=de&amp;q=Plieninger Str 100+ + 70567 Stuttgart&amp;ie=UTF8&amp;t=&amp;z=16&amp;output=embed"
                frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            </v-row>

          </v-container>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDetailRouteInfo: false,
    };
  },
  components: {}
};
</script>