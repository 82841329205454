<template>
  <v-container id="bookNowSection">
    <v-row>
      <v-col>
        <p class="headline">{{ $t("BOOK_NOW") }}</p>
        <p class=""></p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <a href="mailto:cosiapartmentstuttgart@gmail.com">
          <v-avatar :tile="true" width="30" height="30">
            <img :src="require('@/assets/heart.svg')" width="30" alt="logo" />
          </v-avatar>
          {{ $t("BOOK_ROOM_EMAIL") }}
        </a>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <a href="https://www.airbnb.de/rooms/39868113?check_in=2021-01-18&amp;check_out=2021-01-19&amp;s=66&amp;unique_share_id=c0276161-4f69-4d48-b4a4-582e80b5202c&amp;source=embed_widget"
          target="_blank">
          <v-avatar :tile="true" width="30" height="30">
            <img :src="require('@/assets/heart.svg')" width="30" alt="logo" />
          </v-avatar>
          {{ $t("BOOK_ROOM_AIRBNB") }}
        </a>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <a href="https://www.booking.com/hotel/de/cosi-apartment-im-si-centrum.de.html" target="_blank">
          <v-avatar :tile="true" width="30" height="30">
            <img :src="require('@/assets/heart.svg')" width="30" alt="logo" />
          </v-avatar>
          {{ $t("BOOK_ROOM_BOOKING") }}
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>