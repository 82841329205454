<template>
  <v-app id="inspire" class="application">
    <navigation></navigation>
    <switch-language-section></switch-language-section>
    <v-main class="mt-0 pt-0">
      <welcome-section></welcome-section>
      <appartment-section></appartment-section>
      <route-contact-section></route-contact-section>
      <book-now-section></book-now-section>
      <footer-section></footer-section>
    </v-main>
  </v-app>
</template>

<script>
import AppartmentSection from "../components/AppartmentSection.vue";
import BookNowSection from "../components/BookNowSection.vue";
import Navigation from "../components/Navigation.vue";
import RouteContactSection from "../components/RouteContactSection.vue";
import WelcomeSection from "../components/WelcomeSection.vue";
import SwitchLanguageSection from "../components/SwitchLanguageSection.vue";
import FooterSection from '../components/FooterSection.vue';
export default {
  components: {
    Navigation,
    WelcomeSection,
    AppartmentSection,
    RouteContactSection,
    BookNowSection,
    SwitchLanguageSection,
    FooterSection
  },
  data: () => ({})
};
</script>
