import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router';
import { i18n } from './plugins/i18n.js';

Vue.config.productionTip = false

document.title="coSI Appartment SI-Centrum Stuttgart"

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
