<template>
    <v-container>
        <v-row class="mt-15">
            <v-col>
                <v-select class="float-right" :items="langs" return-object :label="$t('YOUR_LANGUAGE')"
                    v-model="selectedLanguage"></v-select>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: "SwitchLanguageSection",
    data() {
        return {
            selectedLanguage: null,
            langs: [
                { text: this.$i18n.t("GERMAN"), key: "de" },
                { text: this.$i18n.t("ENGLISH"), key: "en" }]
        }
    },
    created() {
        this.selectedLanguage = this.getSelectedKey;
    },
    watch: {
        selectedLanguage() {
            this.$i18n.locale = this.selectedLanguage.key;

        }
    },
    computed: {
        getSelectedKey() {
            return this.langs.filter(i => i.key === this.$i18n.locale)[0]
        }
    },


};
</script>