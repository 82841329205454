<template>
  <div id="welcomeSection">

    <welcome-carosel></welcome-carosel>

  </div>
</template>

<script>
import WelcomeCarosel from "./WelcomeCarosel.vue";
export default {
  components: { WelcomeCarosel },
  name: "WelcomeSection",
  data: () => ({}),
};
</script>