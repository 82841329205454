<template>
    <v-app id="disclaimer" class="application">
        <navigation></navigation>
        <v-main class="mt-0 pt-0">
            <v-container>
                <h4>Impressum</h4>
                <p>Anbieter:<br />Christina Herbert<br />Plienigerstraße 100<br />70567 Stuttgart</p>
                <p>Kontakt:<br />E-Mail:
                    cosiapartmentstuttgart@gmail.com<br />Website:
                    www.cosi-stuttgart.de</p>
                <h4>Open source Bibliotheken</h4>
                <p>icons, https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css</p>
                <p>Vuetify, https://github.com/vuetifyjs/vuetifyhttps://github.com/vuetifyjs/vuetify</p>
                <h4>Datenschutzerklärung:</h4>
                <p><strong>Datenschutz</strong></p>

                <p> <strong>Grundlegendes</strong></p>
                <p>

                    Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der
                    Erhebung
                    und Verwendung personenbezogener Daten durch den Websitebetreiber Christina Herbert,
                    Plienigerstraße 100,
                    70567
                    Stuttgart informieren.
                    <br>
                    Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten
                    vertraulich
                    und entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige
                    Weiterentwicklung
                    dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir
                    Ihnen
                    sich
                    die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.
                    <br>
                    Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”) finden Sie
                    in
                    Art.
                    4
                    DSGVO.
                </p>

                <p><strong>Zugriffsdaten</strong></p>
                <p>
                    Der Hosting Anbieter unserer Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres
                    berechtigten
                    Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über Zugriffe auf die Website und speichern diese
                    als
                    „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten werden so protokolliert:
                    <br>
                    • Besuchte Website<br>
                    • Uhrzeit zum Zeitpunkt des Zugriffes<br>
                    • Menge der gesendeten Daten in Byte<br>
                    • Quelle/Verweis, von welchem Sie auf die Seite gelangten<br>
                    • Verwendeter Browser<br>
                    • Verwendetes Betriebssystem<br>
                    • Verwendete IP-Adresse<br>
                    <br>
                    Die Server-Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht. Die Speicherung
                    der
                    Daten
                    erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können. Müssen Daten aus
                    Beweisgründen
                    aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt
                    ist.
                </p>
                <strong>
                    Reichweitenmessung & Cookies</strong>
                <p>

                    Diese Website verwendet keine Cookies.

                </p>

                <strong>Umgang mit Kontaktdaten</strong>
                <p>
                    Diese Webseite bietet keine Möglichkeit personenbezogene Daten zu speichern.
                </p>

                <strong>
                    Umgang mit Kommentaren und Beiträgen</strong>

                <p>
                    Diese Webseite bietet keine Kommentar- und Beiträge Funktion an.
                </p>
                <strong>
                    Usertracking / Google Analytics
                </strong>
                <p>
                    Auf dieser Website wird kein Usertracking Tool eingesetzt.
                </p>

                <strong>Nutzung von Social-Media-Plugins von Facebook</strong>


                <p>Auf dieser Webseite werden keine Social-Media Plugins eingesetzt.</p>

                <strong>Newsletter-Abonnement</strong>
                <p>
                    Diese Webseite versendet keine Newsletter.
                </p>

                <strong>Rechte des Nutzers</strong>
                <p>
                    Diese Webseite speichert keine personenbezogenen Daten.
                </p>

                <strong>Löschung von Daten</strong>
                <p>
                    Diese Webseite speichert keine personenbezogenen Daten.
                </p>

                <strong>Widerspruchsrecht</strong>
                <p>Diese Webseite speichert keine personenbezogenen Daten.</p>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Navigation from "../components/Navigation.vue";

export default {
    name: "DisclaimerPrivacyView",
    components: {
        Navigation,
    }
}
</script>