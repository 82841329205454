
<template>
    <v-app id="inspire" class="application">
        <navigation></navigation>
        <switch-language-section></switch-language-section>
        <v-main class="mt-0 pt-0">
            <v-container>
                <v-row>
                    <v-col>
                        <p class="headline">{{ $t("CHOSE_YOUR_TRANSFER") }}</p>
                    </v-col>
                </v-row>
                <div style="border: none">
                    <v-tabs color="accent-4">
                        <v-tab v-for="card in cards" :key="card.title">{{
                            $t(card.title)
                        }}</v-tab>
                        <v-tab-item>
                            <v-row>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="12">
                                    <h3>{{ $t("CONTACT") }}</h3>
                                    <div>
                                        Si-Centrum<br />
                                        Plieninger Str. 100 <br />
                                        70567 Stuttgart
                                    </div>
                                    <h3>{{ $t('PARKING') }}</h3>
                                    <div>{{ $t('PARKING_INFO') }}</div><br>

                                    <p>
                                        <a href="https://www.apcoa.de/parken/stuttgart/si-centrum-tiefgarage-p2/"
                                            target="_blank">{{ $t('PARKING_INFO_3') }}</a>
                                    </p>

                                    <div>{{ $t('PARKING_INFO_2') }}</div><br>
                                    <div>
                                        Widmaierstraße 128 <br>
                                        70567 Stuttgart <br>

                                    </div>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                                    <v-container fill-height fluid>
                                        <v-row>
                                            <iframe width="600px" height="300px"
                                                src="https://www.google.com/maps?width=600&amp;height=400&amp;hl=de&amp;q=Plieninger Str 100+ + 70567 Stuttgart&amp;ie=UTF8&amp;t=&amp;z=16&amp;output=embed"
                                                frameborder="0" scrolling="no" marginheight="0"
                                                marginwidth="0"></iframe>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item>
                            <v-row>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="12">
                                    <p>{{ $t('TRAIN_INFO') }}</p>
                                    <p>{{ $t('TRAIN_INFO_2') }}</p>
                                    <p>{{ $t('TRAIN_INFO_2_1') }}</p>
                                    <p>{{ $t('TRAIN_INFO_2_2') }}</p>
                                    <p>{{ $t('TRAIN_ACCESS') }}</p>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="12">
                                    <v-container fill-height fluid>
                                        <v-row>
                                            <iframe title="VVS Verbindungswidget" frameborder="0" style="border: 0;"
                                                width="600" height="400"
                                                src="https://www.vvs.de/services/efaaufhp/widgets/a-nach-b.html?destId=de%3A08111%3A360&dest=Salz%C3%A4cker&type=train"></iframe>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item>
                            <v-row>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="12">
                                    <p>{{ $t('PLAIN_INFO') }}</p>
                                    <p class="font-weight-bold">{{ $t('PLAIN_INFO_2') }}</p>
                                    <p>{{ $t('TRAIN_ACCESS') }}</p>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6" xl="12">
                                    <v-container fill-height fluid>
                                        <v-row>
                                            <iframe title="VVS Verbindungswidget" frameborder="0" style="border: 0;"
                                                width="600" height="400"
                                                src="https://www.vvs.de/services/efaaufhp/widgets/a-nach-b.html?destId=de%3A08111%3A360&dest=Salz%C3%A4cker&type=train"></iframe>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs>
                </div>
            </v-container>
            <footer-section></footer-section>
        </v-main>
    </v-app>
</template>
<script>
import FooterSection from '../components/FooterSection.vue';
import Navigation from "../components/Navigation.vue";
import SwitchLanguageSection from "../components/SwitchLanguageSection.vue";

export default {
    name: "CheckInView",
    components: {
        Navigation,
        SwitchLanguageSection,
        FooterSection
    },
    data() {
        return {
            cards: [
                {
                    title: "CAR",
                },
                {
                    title: "TRAIN",
                },
                {
                    title: "PLAIN",
                }]

        }
    }
}
</script>